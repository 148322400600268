<template>
  <div class="info">
    <div v-show="visible">
      <!-- 提议对象 -->
      <van-popup v-model="objectShow" position="bottom">
        <van-picker title="请选择" show-toolbar :columns="selectData.objectList" value-key="label"
                    @confirm="objectConfirm" @cancel="objectShow = false"/>
      </van-popup>
      <van-cell-group v-if="submitData.checkStatus === 3">
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">回复提议</span>
          </template>
        </van-cell>
        <van-cell>
          <van-field name="radio" label="是否采纳议题">
            <template #input>
              <van-radio-group v-model="submitData.adopt" direction="horizontal" disabled>
                <van-radio name="0">不采纳</van-radio>
                <van-radio name="1">采纳</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </van-cell>
        <van-cell title="回复人">
          <van-field v-model="submitData.answer" placeholder="请输入" readonly/>
        </van-cell>
        <van-cell title="回复时间" :value="submitData.replyTime"/>
        <van-cell-group>
          <van-cell title="回复内容" :border="false">
          </van-cell>
          <van-cell :border="false">
            <van-field class="content" v-model="submitData.replyContent" rows="4" autosize type="textarea" placeholder="这是一条提议内容"
                       readonly/>
          </van-cell>
        </van-cell-group>
      </van-cell-group>
      <van-cell-group>
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">基本信息</span>
          </template>
        </van-cell>
        <van-cell title="提议对象" :value="selectMsg.objectTypeStr" @click="objectShow = edit"/>
        <van-cell title="提议标题">
          <van-field v-model="submitData.title" placeholder="请输入" :readonly="!edit"/>
        </van-cell>
        <van-cell-group>
          <van-cell title="提议描述" :border="false">
          </van-cell>
          <van-cell :border="false">
            <van-field class="content" v-model="submitData.proposeContent" rows="4" autosize type="textarea" placeholder="这是一条提议内容"
                       :readonly="!edit"/>
          </van-cell>
          <van-cell v-if="edit||fileList.length!==0">
            <van-uploader v-model="fileList" :multiple="false" @delete="uploaddel" :after-read="uploadRead" :deletable="edit"
                          :before-read="beforeUpload" :show-upload="edit"/>
          </van-cell>
        </van-cell-group>
      </van-cell-group>
      <van-row class="btns" v-if="edit">
        <van-col :span="24">
          <van-button type="info" size="large" :loading="loading" round @click="submit">提交</van-button>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {beforeFileUpload, formatterDateTimeWithOutSec} from "@/utils/utils"
import {getImageStream} from '@/utils/index'

export default {
  data() {
    return {
      visible: true,
      edit: false,
      file: {},
      loading: false,
      objectShow: false,
      proposalTimeShow: false,
      subareaShow: false,
      replyTimeShow: false,
      selectMsg: {
        objectTypeStr: '请选择'
      },
      submitData: {
        id: '',
        orgId: 0,
        userId: 0,
        objectType: '',
        title: '',
        answer: '',
        adopt: '0',
        replyTime: '',
        replyContent: '',
        proposeContent: '',
        checkStatus: 0,
        newFileMessages: [],
        deleteFiles: []
      },
      selectData: {
        objectList: [],
      },
      fileList: [],
    }
  },
  components: {
    topBar
  },
  methods: {
    // 删除图片
    uploaddel(file, detail) {
      let i = detail.index
      this.submitData.newFileMessages.splice(i, 1)
      if (file.id !== 0) {
        this.submitData.deleteFiles.push(file.id)
      }
    },
    // 文件上传前的回调
    beforeUpload(file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration: 0,
        overlay: true
      })
      return beforeFileUpload(file)
    },
    // 上传图片
    uploadRead(file) {
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
        let formdata = new FormData();
        formdata.append("file", item.file)
        formdata.append("path", 'files/future/letter/temporary/' + this.submitData.orgId)
        this.$http({
          url: this.$http.adornUrl('/wxapp/file/upload'),
          method: 'post',
          data: formdata
        }).then(({data}) => {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            let fileMessage = data.fileMessage
            fileMessage['uid'] = this.fileList.length != 0 ? this.fileList.length : 0
            this.submitData.newFileMessages.push(fileMessage)
            return true
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },
    // 初始化
    init() {
      let nowDate = new Date()
      this.maxDate = nowDate
      this.getPeopleList()
      if (this.submitData.id) {
        this.edit = false
        this.getInfo()
      } else {
        this.edit = true
      }
    },
    // 获取详情
    getInfo() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/future/letter/info/' + this.submitData.id),
        method: 'post'
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.submitData.objectType = data.futureLetter.proposedTarget
          this.selectMsg.objectTypeStr = data.futureLetter.proposedTargetStr
          this.submitData.title = data.futureLetter.letterTitle
          this.submitData.proposeContent = data.futureLetter.letterContent
          this.submitData.checkStatus = data.futureLetter.checkStatus
          if (this.submitData.checkStatus === 3) {
            this.submitData.adopt = data.futureLetter.adopt.toString()
            this.submitData.answer = data.futureLetter.checkName
            this.submitData.replyTime = data.futureLetter.checkTime
            this.submitData.replyContent = data.futureLetter.checkContent
          }
          let files = data.futureLetter.fileMessages
          let urls = []
          for (let i in files) {
            let file = files[i]
            let u
            u = getImageStream(files[i].relativePath)
            let url = {
              uid: urls.length,
              id: file.id,
              status: 'done',//uploading上传中，done上传完成，error上传失败
              fileType: file.fileType,
              url: u,
              realUrl: file.relativePath.replace(/\\/g, "/")
            }
            urls.push(url);
          }
          this.fileList = urls
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取人大列表
    getPeopleList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/app/userinfoauxiliary/getNpcList'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          data.list.map(item => {
            this.selectData.objectList.push({value: item.id, label: item.name})
          })
          this.selectData.objectList.push({value: 0, label: '全体代表'})
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 提交
    submit() {
      if (this.submitData.title === '') {
        return this.$toast.fail('失败:提议标题不能为空');
      }
      if (this.submitData.proposeContent === '') {
        return this.$toast.fail('失败:提议描述不能为空');
      }
      this.loading = true
      this.$http({
        url: this.$http.adornUrl(`/wxapp/future/letter/save`),
        method: 'post',
        data: {
          id: this.submitData.id,
          community: this.submitData.orgId,
          userId: this.$globalData.userInfo.userId,
          proposedTarget: this.submitData.objectType,
          letterTitle: this.submitData.title,
          letterContent: this.submitData.proposeContent,
          newFileMessages: this.submitData.newFileMessages,
          deleteFiles: this.submitData.deleteFiles
        }
      }).then(({data}) => {
        if (data.code == 0) {
          this.$toast.success({
            message: '提议成功',
            duration: 3000,
            onOpened: () => {
              setTimeout(() => {
                this.$router.go(-1)
              }, 1500)
            }
          })
        } else {
          this.loading = false
          this.$toast.fail(data.msg);
        }
      })
      this.loading = false
    },
    // 巡查地点选择
    objectConfirm(value) {
      if (value) {
        this.selectMsg.objectTypeStr = value.label
        this.submitData.objectType = value.value
      }
      this.objectShow = false
    },
  },
  created() {
    this.submitData.id = this.$route.query.id || ''
    if (this.$route.query.userId) {
      this.submitData.objectType = this.$route.query.userId
      this.selectMsg.objectTypeStr = this.$route.query.name
    }
    document.querySelector('html').style.backgroundColor = '#fff'
    this.submitData.orgId = this.$orgId
    this.submitData.userId = this.$globalData.userInfo.userId
    this.init()
  },
  beforeDestroy() {
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>
<style>
a {
  color: rgb(4, 50, 255);
}

.blue-box {
  background-color: #007AFF;
  height: 35px;
  width: 6px;
  border-radius: 6px;
  float: left;
  margin-top: 8px;
  margin-right: 8px;
}
</style>
<style lang="scss" scoped>
::v-deep .van-field__control {
  text-align: right;
}

.btns {
  position: fixed;
  bottom: 50px;
  left: 0;
  width: 100%;
  padding: 0 20px;
  margin-top: 30px;
}

.van-divider {
  border-color: #000;
}

.info {
  margin-bottom: 150px;
}

.van-field {
  padding: 0;
}
.content {
  border-radius: 10px;
  padding: 20px;
  background-color: #F5F5F5;
  ::v-deep .van-field__control {
    text-align: left !important;
  }
  ::v-deep .van-cell::after {
    border-bottom: none;
  }
}
.splitLine {
  height: 20px;
  width: 100%;
  background-color: #F5F5F5;
}
::v-deep .van-radio-group--horizontal{
  margin-left: 185px;
}
</style>
